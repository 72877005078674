<template>
  <b-container class="pb-3" fluid="lg">
    <b-breadcrumb class="mt-3">
      <b-breadcrumb-item :to="{ name: 'HistoryEvent' }">
        歷史評鑑列表
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{ name: 'EventDashboard', params: { eventID: eventID } }"
      >
        <b-icon v-if="EventData.type === 'remote'" icon="cloud"></b-icon>
        {{ EventData.name }}
        <b-badge v-if="EventData.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="EventData.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="EventData.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>評鑑儀錶板</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <h1>{{ `${EventData.name} 評鑑儀錶板` }}</h1>
      </b-col>
    </b-row>

    <b-row class="mt-3" cols="1">
      <b-col v-if="roundList.length === 0">
        <b-alert show variant="warning" dismissible>
          此評鑑不存在任何資料輪
        </b-alert>
      </b-col>

      <template v-for="(r, i) in roundList">
        <b-col :key="i">
          <RoundCard
            :id="`RoundCard-${r.id}`"
            v-if="EditCardStatusList[i] === 0"
            :eventID="eventID"
            :RoundData="r"
            @updateRound="GetRounds()"
          >
          </RoundCard>

          <RoundFromCard
            :id="`RoundCard-${r.id}`"
            v-if="EditCardStatusList[i] === 1"
            :eventID="eventID"
            :NewRoundFlag="false"
            :RoundData="r"
            @updateRound="GetRounds()"
          ></RoundFromCard>
        </b-col>
      </template>

      <!-- head-judge or admin -->
      <b-col>
        <RoundFromCard
          id="NewRoundCard"
          v-if="Controllable && EventData.status === 'ongoing'"
          :eventID="eventID"
          :NewRoundFlag="true"
          @updateRound="GetRounds()"
        >
        </RoundFromCard>
      </b-col>
    </b-row>
    <b-row v-if="Controllable" class="mt-3">
      <b-col>
        <b-btn
          :disabled="EventData.status !== 'ongoing'"
          variant="danger"
          size="lg"
          v-b-modal="`modal-doneEvent-${EventData.id}`"
        >
          結束評鑑
        </b-btn>
        <b-modal
          :id="`modal-doneEvent-${EventData.id}`"
          :title="`確認結束 ${EventData.name} 活動`"
          @ok="FinishEvent()"
        >
          <p class="my-1">是否確認結束，結束後將無法修改、重啟</p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RoundCard from "../components/RoundCard.vue";
import RoundFromCard from "../components/RoundFormCard.vue";
import { API } from "../store/api";
export default {
  name: "EventDashboard",
  components: { RoundCard, RoundFromCard },
  props: {
    eventID: {
      type: Number,
      require: true
    }
  },
  watch: {
    roundList(newVal) {
      this.EditCardStatusList.length = newVal.length;
      this.EditCardStatusList.fill(0);
      this.EditCardStatusList.forEach((cur, index, arr) => {
        if (newVal[index].status === "editing") {
          arr[index] = 1;
        } else {
          arr[index] = 0;
        }
      });
    }
  },
  computed: {
    Controllable() {
      return this.$store.state.me.is_admin || this.$store.state.me.is_headJudge;
    }
  },
  data() {
    return {
      EditCardStatusList: [],
      roundList: [
        {
          id: null,
          event_id: null,
          name: null,
          status: null,
          target_ids: [],
          rater_ids: [],
          created_at: null,
          updated_at: null
        }
      ],
      EventData: {}
    };
  },
  async mounted() {
    this.GetRounds();
  },
  methods: {
    async GetRounds() {
      let data = await API.GetRounds(this.eventID);
      this.roundList = data.rounds;
      this.EventData = data.event;
    },
    async PUTEvent(status = "done") {
      let res = await API.PUTEvent({ ...this.EventData, status });
      if (res.status === 201) {
        this.$bvToast.toast("成功修改活動", {
          title: "操作成功通知",
          variant: "success",
          autoHideDelay: 1000
        });
      }
      await this.GetRounds();
    },
    async FinishEvent() {
      let res = await API.FinishEvent(this.EventData.id);
      if (res.status === 201) {
        this.$bvToast.toast("成功結束活動", {
          title: "操作成功通知",
          variant: "success",
          autoHideDelay: 1000
        });
      }
      this.$router.push({ name: "HistoryEvent" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
