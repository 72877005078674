var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-start cardBody mx-auto my-2 "},[(_vm.NewRoundFlag === true && _vm.state === 1)?_c('div',[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.state = 2}}},[_vm._v(" 建立新的評分輪 ")])],1):_vm._e(),(
      (_vm.NewRoundFlag === true && _vm.state === 2) ||
        (_vm.RoundData != undefined && _vm.RoundData.status === 'editing')
    )?_c('div',{staticClass:"d-flex flex-column justify-content-center "},[_c('b-btn',{staticClass:"closeBtn",on:{"click":function($event){_vm.RoundData === undefined ? (_vm.state = 1) : _vm.DeleteRound()}}},[_c('b-img',{attrs:{"src":require("../assets/x.svg")}})],1),_c('div',{staticClass:"d-flex justify-content-start"},[_c('label',{staticClass:"text-left m-1"},[_vm._v(" 輪名稱 "),_c('b-form-input',{staticStyle:{"max-width":"15rem"},attrs:{"placeholder":"輸入 輪名稱"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]),_c('div',{staticClass:"d-flex justify-content-around"},[_c('label',{staticClass:"text-left w-50 pr-2"},[_vm._v(" 選擇豆子 "),_c('formTags',{staticClass:"mx-1",attrs:{"options":_vm.beansList.map(function (b) { return b.text; }),"valueProp":_vm.NewRoundFlag
              ? []
              : _vm.form.target_ids.map(
                  function (t) { return _vm.beansList.find(function (b) { return b.value == t; }).text; }
                )},on:{"input":function($event){_vm.form.target_ids = $event.map(
              function (d) { return _vm.beansList.find(function (b) { return b.text == d; }).value; }
            )}}})],1),_c('label',{staticClass:"text-left w-50"},[_vm._v(" 選擇評審 "),_c('formTags',{staticClass:"mx-1 ",attrs:{"options":_vm.ratersList.map(function (b) { return b.text; }),"valueProp":_vm.NewRoundFlag
              ? []
              : _vm.form.rater_ids.map(
                  function (d) { return _vm.ratersList.find(function (r) { return r.value == d; }).text; }
                )},on:{"input":function($event){_vm.form.rater_ids = $event.map(
              function (d) { return _vm.ratersList.find(function (b) { return b.text == d; }).value; }
            )}}})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-btn',{staticClass:"mx-1",on:{"click":function($event){_vm.NewRoundFlag ? _vm.NewRound() : _vm.AlterRound()}}},[_vm._v(" 暫存輪資料 ")]),_c('b-btn',{staticClass:"mx-1",attrs:{"variant":"primary"},on:{"click":function($event){_vm.NewRoundFlag ? _vm.NewRound() : _vm.AlterRound('ongoing')}}},[_vm._v(" 開始此輪評分 ")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }