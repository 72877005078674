var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-start cardBody my-2",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap"},[_c('h2',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.RoundData.name)+" "),(_vm.RoundData.status === 'ongoing')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" 進行中 ")]):(_vm.RoundData.status === 'done')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" 已結束 ")]):(_vm.RoundData.status === 'editing')?_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" 編輯中 ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" 無狀態 ")])],1),_c('b-btn',{staticClass:"m-1",attrs:{"disabled":(function() {
          if (_vm.RoundData.status !== 'ongoing') { return true; }
          if (!_vm.isRaterInRound) { return true; }

          var unsubmitScores = _vm.TableData.filter(
            function (score) { return score.status === 'unsubmit'; }
          );
          if (unsubmitScores.length === 0) {
            return true;
          }
          return !unsubmitScores.every(function (score) { return score.is_submittable; });
        })(),"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.submitAllScore()}}},[_vm._v(" 送繳全部成績 ")])],1),(_vm.fields.length !== 0)?_c('div',{staticClass:"mt-1 px-3 tableCard d-flex flex-column align-content-center  justify-content-center"},[_c('b-table',{staticClass:"mt-3",attrs:{"hover":"","bordered":"","responsive":"lg","striped":"","fields":_vm.fields,"items":_vm.TableData},scopedSlots:_vm._u([{key:"cell(target_code)",fn:function(data){return [_c('b-btn',{attrs:{"variant":data.item.status === 'submitted' ? 'secondary' : 'primary',"size":"sm","pill":""},on:{"click":function($event){return _vm.goToScore(data.item.id)}}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell()",fn:function(data){return [(data.field.addressable)?_c('router-link',{class:data.item.status === 'submitted' ? ['text-secondary'] : null,attrs:{"to":_vm.goToScore(data.item.id, '#' + data.field['key'], true)}},[_vm._v(" "+_vm._s(data.value != null ? data.value : "---")+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.value != null ? data.value : "---")+" ")])]}}],null,false,2529474612)})],1):_c('div',[_c('h3',[_vm._v("您並非此輪評審")])]),(_vm.Controllable)?_c('div',{staticClass:"d-flex justify-content-lg-center flex-wrap h-50 mt-3"},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("modal-doneEvent-" + (_vm.RoundData.id))),expression:"`modal-doneEvent-${RoundData.id}`"}],staticClass:"m-1",attrs:{"variant":"danger","disabled":_vm.RoundData.status === 'done'}},[_vm._v(" 結束此輪評分 "),_c('b-modal',{attrs:{"id":("modal-doneEvent-" + (_vm.RoundData.id)),"title":("確認結束 " + (_vm.RoundData.name) + " 評分")},on:{"ok":function($event){return _vm.AlterRound('done')}}},[_c('p',{staticClass:"my-1"},[_vm._v("是否確認結束，結束後將無法修改、重啟")])])],1),_c('b-btn',{staticClass:"m-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'ScoreReview',
          params: { eventID: _vm.eventID, roundID: _vm.RoundData.id }
        })}}},[_vm._v(" 前往分數檢討 ")]),_c('b-btn',{staticClass:"m-1",attrs:{"variant":"info"},on:{"click":function($event){return _vm.$router.push({
          name: 'EventStatus',
          params: { eventID: _vm.eventID, roundID: _vm.RoundData.id }
        })}}},[_vm._v(" 前往評鑑狀態 ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }