<template>
  <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
  <b-form-tags
    v-model="value"
    size="lg"
    class="mb-2"
    @input="$emit('input', value)"
    :disabled="disabled"
    add-on-change
    no-outer-focus
  >
    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
      <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
        <li v-for="(tag, index) in tags" :key="index" class="list-inline-item">
          <b-form-tag
            pill
            @remove="removeTag(tag)"
            :title="tag"
            :disabled="disabled"
            variant="info"
          >
            {{ tag }}
          </b-form-tag>
        </li>
      </ul>
      <b-form-select
        :form="inputAttrs.form"
        :id="inputAttrs.id"
        :value="inputAttrs.value"
        @change="inputHandlers.change"
        @input="inputHandlers.input"
        @keydown="inputHandlers.keydown"
        :disabled="disabled || availableOptions.length === 0"
        :options="availableOptions"
      >
        <template #first>
          <option disabled value="">請選擇選項...</option>
        </template>
      </b-form-select>
    </template>
  </b-form-tags>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    valueProp: {
      type: Array,
      default() {
        return [];
      }
    },
    options: {
      type: Array,
      require: true
    }
  },
  watch: {
    valueProp: {
      handler(newVal, oldVal) {
        if (oldVal.length === 0 && newVal.length !== 0) {
          newVal.forEach(p => this.value.push(p));
        }
      }
    }
  },
  data() {
    return {
      value: []
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter(opt => this.value.indexOf(opt) === -1);
    }
  }
};
</script>
