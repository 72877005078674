<template>
  <div class="d-flex flex-column justify-content-start cardBody mx-auto my-2 ">
    <!-- EDIT -->
    <div v-if="NewRoundFlag === true && state === 1">
      <b-btn @click="state = 2" variant="primary">
        建立新的評分輪
      </b-btn>
    </div>
    <div
      v-if="
        (NewRoundFlag === true && state === 2) ||
          (RoundData != undefined && RoundData.status === 'editing')
      "
      class="d-flex flex-column justify-content-center "
    >
      <!-- close btn -->
      <b-btn
        @click="RoundData === undefined ? (state = 1) : DeleteRound()"
        class="closeBtn"
      >
        <b-img src="../assets/x.svg" />
      </b-btn>

      <div class="d-flex justify-content-start">
        <label class="text-left m-1">
          輪名稱
          <b-form-input
            style="max-width:15rem"
            v-model="form.name"
            placeholder="輸入 輪名稱"
          ></b-form-input>
        </label>
      </div>

      <div class="d-flex justify-content-around">
        <label class="text-left w-50 pr-2">
          選擇豆子
          <formTags
            class="mx-1"
            :options="beansList.map(b => b.text)"
            :valueProp="
              NewRoundFlag
                ? []
                : form.target_ids.map(
                    t => beansList.find(b => b.value == t).text
                  )
            "
            @input="
              form.target_ids = $event.map(
                d => beansList.find(b => b.text == d).value
              )
            "
          ></formTags>
        </label>

        <label class="text-left w-50">
          選擇評審
          <formTags
            class="mx-1 "
            :options="ratersList.map(b => b.text)"
            :valueProp="
              NewRoundFlag
                ? []
                : form.rater_ids.map(
                    d => ratersList.find(r => r.value == d).text
                  )
            "
            @input="
              form.rater_ids = $event.map(
                d => ratersList.find(b => b.text == d).value
              )
            "
          ></formTags>
        </label>
      </div>
      <div class="d-flex justify-content-center">
        <b-btn class="mx-1" @click="NewRoundFlag ? NewRound() : AlterRound()">
          暫存輪資料
        </b-btn>
        <b-btn
          class="mx-1"
          variant="primary"
          @click="NewRoundFlag ? NewRound() : AlterRound('ongoing')"
        >
          開始此輪評分
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import formTags from "../components/form-tags.vue";
import { API } from "../store/api";
export default {
  name: "RoundFromCard",
  components: { formTags },
  props: {
    NewRoundFlag: {
      type: Boolean,
      require: true,
      default() {
        return false;
      }
    },
    eventID: {
      type: Number,
      require: true
    },
    RoundData: {
      type: Object,
      require: false
    }
  },
  watch: {},
  computed: {
    isRaterInRound() {
      return this.ratersList.some(r => r.user_id === this.$store.state.me.id);
    },
    Controllable() {
      let rater = this.ratersList.find(
        r => r.user_id === this.$store.state.me.id
      );
      return this.$store.state.me.is_admin || rater !== undefined
        ? rater.type === "head"
        : false;
    }
  },
  async mounted() {
    this.ratersList = (await API.GetRaters(this.eventID)).map(r => {
      return {
        value: r.id,
        text: `${r.user_name}[${r.code}]`,
        ...r
      };
    });

    this.beansList = (await API.GetTargets(this.eventID)).map(t => {
      return {
        value: t.id,
        text: t.code,
        ...t
      };
    });

    // mapping RoundData to form
    if (this.NewRoundFlag === false) {
      Object.keys(this.RoundData).forEach(key =>
        this.$set(this.form, key, this.RoundData[key])
      );
    }
  },
  methods: {
    async NewRound() {
      let res = await API.AddRound(
        this.eventID,
        this.form.name,
        this.form.target_ids,
        this.form.rater_ids
      );
      if (res.status === 201) {
        this.$bvToast.toast("成功建立新輪", {
          title: "操作成功通知",
          variant: "success",
          autoHideDelay: 1000
        });
      }
      this.state = 1;
      this.$emit("updateRound");
    },
    async AlterRound(status = "editing") {
      let res = await API.PUTRound(
        this.RoundData.id,
        this.form.name,
        status,
        this.form.target_ids,
        this.form.rater_ids
      );
      // Object.keys(this.form).forEach(key => (this.from[key] = null));
      if (res.status === 201) {
        this.$bvToast.toast("成功儲存輪資料", {
          title: "操作成功通知",
          variant: "success",
          autoHideDelay: 1000
        });
      }
      this.state = 1;
      this.$emit("updateRound");
    },
    async DeleteRound() {
      await API.DeleteRound(this.RoundData.id);
      this.$emit("updateRound");
    }
  },
  data() {
    return {
      beansList: [],
      ratersList: [],
      state: 1, // state machine
      form: {
        name: "",
        status: null,
        target_ids: [],
        rater_ids: []
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.cardBody {
  position: relative;
  max-width: 1223px;
  padding: 1rem 2rem 1rem 1.5rem;

  background: linear-gradient(135deg, #ededed 0%, #ffffff 100%);
  box-shadow: -8px 8px 16px rgba(165, 165, 165, 0.2),
    8px -8px 16px rgba(165, 165, 165, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(165, 165, 165, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(165, 165, 165, 0.5);

  box-sizing: border-box;

  border-radius: 7px;
}

.closeBtn {
  background: #eb5757;
  border-radius: 4px;

  position: absolute;
  width: 26px;
  height: 26px;
  right: 19px;
  top: 17px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tableCard {
  background: #ffffff;
  box-shadow: -5px 5px 10px rgba(176, 176, 176, 0.2),
    5px -5px 10px rgba(176, 176, 176, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(176, 176, 176, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(176, 176, 176, 0.5);

  border-radius: 17px;
}
</style>
