<template>
  <div
    class="d-flex flex-column justify-content-start cardBody my-2"
    style="overflow-x: hidden;"
  >
    <!-- header of table -->
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h2 class="text-left">
        {{ RoundData.name }}
        <b-badge v-if="RoundData.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-else-if="RoundData.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-else-if="RoundData.status === 'editing'" variant="info">
          編輯中
        </b-badge>
        <b-badge v-else variant="danger">
          無狀態
        </b-badge>
      </h2>

      <b-btn
        @click="submitAllScore()"
        :disabled="
          (function() {
            if (RoundData.status !== 'ongoing') return true;
            if (!isRaterInRound) return true;

            let unsubmitScores = TableData.filter(
              score => score.status === 'unsubmit'
            );
            if (unsubmitScores.length === 0) {
              return true;
            }
            return !unsubmitScores.every(score => score.is_submittable);
          })()
        "
        class="m-1"
        size="sm"
        variant="danger"
      >
        送繳全部成績
      </b-btn>
    </div>

    <!-- table -->
    <div
      v-if="fields.length !== 0"
      class="mt-1 px-3 tableCard d-flex flex-column align-content-center  justify-content-center"
    >
      <b-table
        class="mt-3"
        hover
        bordered
        responsive="lg"
        striped
        :fields="fields"
        :items="TableData"
      >
        <!-- 豆號 欄位 -->
        <template #cell(target_code)="data">
          <b-btn
            :variant="
              data.item.status === 'submitted' ? 'secondary' : 'primary'
            "
            size="sm"
            pill
            @click="goToScore(data.item.id)"
          >
            {{ data.value }}
          </b-btn>
        </template>

        <!-- 其餘 欄位 -->
        <template #cell()="data">
          <router-link
            :class="
              data.item.status === 'submitted' ? ['text-secondary'] : null
            "
            v-if="data.field.addressable"
            :to="goToScore(data.item.id, '#' + data.field['key'], true)"
          >
            {{ data.value != null ? data.value : "---" }}
          </router-link>
          <p class="mb-0" v-else>
            {{ data.value != null ? data.value : "---" }}
          </p>
        </template>
      </b-table>
    </div>
    <div v-else>
      <h3>您並非此輪評審</h3>
    </div>
    <div
      v-if="Controllable"
      class="d-flex justify-content-lg-center flex-wrap h-50 mt-3"
    >
      <b-btn
        class="m-1"
        variant="danger"
        v-b-modal="`modal-doneEvent-${RoundData.id}`"
        :disabled="RoundData.status === 'done'"
      >
        結束此輪評分
        <b-modal
          :id="`modal-doneEvent-${RoundData.id}`"
          :title="`確認結束 ${RoundData.name} 評分`"
          @ok="AlterRound('done')"
        >
          <p class="my-1">是否確認結束，結束後將無法修改、重啟</p>
        </b-modal>
      </b-btn>
      <b-btn
        @click="
          $router.push({
            name: 'ScoreReview',
            params: { eventID: eventID, roundID: RoundData.id }
          })
        "
        class="m-1"
        variant="primary"
      >
        前往分數檢討
      </b-btn>
      <b-btn
        @click="
          $router.push({
            name: 'EventStatus',
            params: { eventID: eventID, roundID: RoundData.id }
          })
        "
        class="m-1"
        variant="info"
      >
        前往評鑑狀態
      </b-btn>
    </div>
  </div>
</template>

<script>
import { API } from "../store/api";
export default {
  name: "RoundCard",
  props: {
    eventID: {
      type: Number,
      require: true
    },
    RoundData: {
      type: Object
    }
  },
  watch: {
    RoundData: {
      deep: true,
      handler() {
        this.DataUpdate();
      }
    }
  },
  computed: {
    isRaterInRound() {
      return this.RoundData.raters.some(
        r => r.user_id === this.$store.state.me.id
      );
    },
    Controllable() {
      return this.$store.state.me.is_admin || this.$store.state.me.is_headJudge;
    },
    fields() {
      switch (this.RoundData.status) {
        case "ongoing":
          return this.isRaterInRound ? this.tableFields.ongoin_isRater : [];
        case "done":
          return this.isRaterInRound
            ? this.tableFields.done_isRater
            : this.tableFields.stat_fields;
        default:
          return [];
      }
    }
  },
  async mounted() {
    await this.DataUpdate();
  },
  methods: {
    async submitAllScore() {
      await Promise.all(
        this.TableData.filter(score => score.is_submittable).map(item =>
          API.SubmitScore(item.id)
        )
      );
      this.DataUpdate();
    },
    async DataUpdate() {
      if (this.RoundData.status === "done") {
        // getting statData
        this.statData = await API.GetRatersCuppingSummary(this.RoundData.id);
      }

      if (this.isRaterInRound) {
        let Scores = await API.GetMyRoundScores(this.RoundData.id);
        this.TableData = Scores.map(element =>
          Object.assign(element, this.statData["" + element.target_id])
        );
      } else {
        this.TableData = this.RoundData.targets.map(t =>
          Object.assign({ target_code: t.code }, this.statData["" + t.id])
        );
      }
    },
    async AlterRound(status = "editing") {
      let res = await API.PUTRound(
        this.RoundData.id,
        this.RoundData.name,
        status,
        this.RoundData.target_ids,
        this.RoundData.rater_ids
      );

      if (res.status === 201) {
        this.$bvToast.toast(`${this.RoundData.name}成功儲存輪資料`, {
          title: "通知",
          variant: "success",
          autoHideDelay: 500
        });
      }
      this.$emit("updateRound");
    },
    goToScore(ScoreID, hash = null, locationObject = false) {
      if (this.isRaterInRound) {
        let _location = {
          name: "Scoring",
          params: {
            eventID: this.eventID,
            roundID: this.RoundData.id,
            scoreID: ScoreID
          },
          hash: hash ? `${hash}_${ScoreID}` : undefined
        };
        if (locationObject) {
          return _location;
        } else {
          this.$router.push(_location);
        }
      } else {
        this.$bvToast.toast(`你不是該輪的評審`, {
          title: "Alert",
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: true
        });
      }
    }
  },
  data() {
    return {
      TableData: [],
      statData: {},
      tableFields: {
        ongoin_isRater: [
          {
            key: "target_code",
            label: "豆號",
            sortable: true,
            addressable: true
          },
          {
            key: "aroma_score",
            label: "香氣",
            sortable: true,
            addressable: true
          },
          {
            key: "flavor_score",
            label: "風味",
            sortable: true,
            addressable: true
          },
          {
            key: "aftertaste_score",
            label: "餘韻",
            sortable: true,
            addressable: true
          },
          {
            key: "acidity_score",
            label: "酸質",
            sortable: true,
            addressable: true
          },
          {
            key: "body_score",
            label: "醇度",
            sortable: true,
            addressable: true
          },
          {
            key: "uniformity_score",
            label: "一致性",
            sortable: true,
            addressable: true
          },
          {
            key: "cleancup_score",
            label: "乾淨度",
            sortable: true,
            addressable: true
          },
          {
            key: "sweetness_score",
            label: "甜感",
            sortable: true,
            addressable: true
          },
          {
            key: "balance_score",
            label: "平衡感",
            sortable: true,
            addressable: true
          },
          {
            key: "overall_score",
            label: "綜評",
            sortable: true,
            addressable: true
          },
          {
            key: "defect",
            label: "瑕疵",
            sortable: true,
            addressable: true
          },
          {
            key: "final",
            label: "總分",
            sortable: true,
            addressable: true
          }
        ],
        done_isRater: [
          {
            key: "target_code",
            label: "豆號",
            sortable: true,
            addressable: true
          },
          {
            key: "total",
            label: "分數",
            sortable: true,
            addressable: true
          },
          {
            key: "defect",
            label: "瑕疵",
            sortable: true,
            addressable: true
          },
          {
            key: "final",
            label: "總分",
            sortable: true,
            addressable: true
          },
          {
            key: "max",
            label: "最大值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "min",
            label: "最小值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "avg",
            label: "平均值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "trim_avg",
            label: "去極值平均數",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "count",
            label: "已送評人數",
            sortable: true,
            variant: "info",
            addressable: false
          }
        ],
        stat_fields: [
          {
            key: "target_code",
            label: "豆號",
            sortable: true,
            addressable: false
          },
          {
            key: "max",
            label: "最大值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "min",
            label: "最小值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "avg",
            label: "平均值",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "trim_avg",
            label: "去極值平均數",
            sortable: true,
            variant: "info",
            addressable: false
          },
          {
            key: "count",
            label: "已送評人數",
            sortable: true,
            variant: "info",
            addressable: false
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.cardBody {
  position: relative;
  max-width: 1223px;
  padding: 1rem 2rem 1rem 1.5rem;

  background: linear-gradient(135deg, #ededed 0%, #ffffff 100%);
  box-shadow: -8px 8px 16px rgba(165, 165, 165, 0.2),
    8px -8px 16px rgba(165, 165, 165, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(165, 165, 165, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(165, 165, 165, 0.5);

  box-sizing: border-box;

  border-radius: 7px;
}

.closeBtn {
  background: #eb5757;
  border-radius: 4px;

  position: absolute;
  width: 26px;
  height: 26px;
  right: 19px;
  top: 17px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tableCard {
  background: #ffffff;
  box-shadow: -5px 5px 10px rgba(176, 176, 176, 0.2),
    5px -5px 10px rgba(176, 176, 176, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(176, 176, 176, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(176, 176, 176, 0.5);

  border-radius: 17px;
}
</style>
